<template>
    <standard-page definition="Create a new template/definition for DID or Claim" title="New Template">
        <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
            <span>Catalogs</span>
        </template>
        <template v-slot:buttons>
            <span class="d-none d-xl-block">
            <button @click="$refs.template_upload.click()" class="d-none d-xl-block btn float-right btn-indigo rounded font-weight-bold mg-r-5" id="rateuploadbtn">
                <i class="fa fa-upload mr-1"/> Upload Template
            </button>
            </span>
        </template>
        <template v-slot:content>
            <div class="tab-pane-body">
                <div class="row row-sm mg-t-20 mg-b-20">
                    <div class="col-lg-3 ">
                        <select :class="{'is-invalid': submitted && errors.has('type')}" :name="'type'" aria-hidden="true"
                                class="form-control select2-no-search select2-hidden-accessible rounded" data-select2-id="13" data-vv-as="Attribute Type" style="height: 42px" tabindex="-1"
                                v-model="did_template.template_type" v-validate="'required'">
                            <option v-bind:value=null>--- Please Select a type ---</option>
                            <option v-bind:key="type" v-for="(type) in template_types">{{type}}</option>
                        </select>
                        <div class="invalid-feedback" v-if="submitted && errors.has('name')">{{ errors.first('name') }}</div>
                    </div>
                    <div class="col-lg-3 ">
                        <input :class="{'is-invalid': submitted && errors.has('name')}" class="form-control rounded" data-vv-as="DID name" id="name" name="name" placeholder="DID Template name"
                               type="text" v-model="did_template.name" v-validate="'required'">
                        <div class="invalid-feedback" v-if="submitted && errors.has('name')">{{ errors.first('name') }}</div>
                    </div>
                </div>
                <div class="row row-xs align-items-center mg-b-20 pd-30 pd-sm-40 bg-gray-200" v-for="(row, index) in did_template.properties">
                    <div class="col-md-2 mg-t-5 mg-md-t-0">
                        <input :class="{'is-invalid': submitted && errors.has('name'+index)}" :name="'name'+index" class="form-control rounded" data-vv-as="Attribute Name" placeholder="Enter Key"
                               style="height: 42px" type="text" v-model="row.name" v-validate="'required'">
                        <div class="invalid-feedback" v-if="submitted && errors.has('name'+index)">{{ errors.first('name'+index) }}</div>
                    </div>
                    <div class="col-md-2 mg-t-5 mg-md-t-0">
                        <select :class="{'is-invalid': submitted && errors.has('type'+index)}" :name="'type'+index" @change="changeDataType(row)" aria-hidden="true"
                                class="form-control select2-no-search select2-hidden-accessible rounded" data-select2-id="13" data-vv-as="Attribute Type" style="height: 42px" tabindex="-1"
                                v-model="row.fact_type" v-validate="'required'">
                            <option v-bind:value=null>--- Please Select a type ---</option>
                            <option v-bind:key="type" v-for="(type) in data_types">{{type}}</option>
                        </select>
                        <div class="invalid-feedback" v-if="submitted && errors.has('type'+index)">{{ errors.first('type'+index) }}</div>
                    </div>
                    <div class="col-md-2 mg-t-5 mg-md-t-0">
                        <input :name="'options'+index" :readonly="row.fact_type !== 'Text'" class="form-control rounded" placeholder="Options" v-model="row.options">
                    </div>
                    <div class="col-md-2 mg-t-5 mg-md-t-0">
                        <div class="form-check form-check-inline">
                            <input :class="{ 'is-invalid' : submitted && errors.has('required'+index) }" :name="'required'+index" :value="true" class="form-check-input" type="radio"
                                   v-model="row.required" v-validate="'required'">
                            <label class="form-check-label" for="Yes">Required</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input :class="{ 'is-invalid' : submitted && errors.has('required'+index) }" :name="'required'+index" :value="false" class="form-check-input" type="radio"
                                   v-model="row.required" v-validate="'required'">
                            <label class="form-check-label" for="No">Not Required</label>
                        </div>
                        <div class="invalid-feedback" v-if="submitted && errors.has('required'+index)">{{ errors.first('required'+index) }}</div>
                    </div>
                    <div class="col-md-2 mg-t-5 mg-md-t-0">
                        <div class="form-check form-check-inline">
                            <input :class="{ 'is-invalid' : submitted && errors.has('private'+index) }" :disabled="did_template.template_type === 'Claim'" :name="'private'+index" :value="false"
                                   class="form-check-input" type="radio"
                                   v-model="row.private" v-validate="'required'">
                            <label class="form-check-label" for="No">Public</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input :class="{ 'is-invalid' : submitted && errors.has('private'+index) }" :disabled="did_template.template_type === 'Claim'" :name="'private'+index" :value="true"
                                   class="form-check-input" type="radio"
                                   v-model="row.private" v-validate="'required'">
                            <label class="form-check-label" for="Yes">Private</label>
                        </div>
                        <div class="invalid-feedback" v-if="submitted && errors.has('private'+index)">{{ errors.first('private'+index) }}</div>
                    </div>
                    <div class="col-md mg-t-10 mg-md-t-0" v-if="did_template.properties.length > 1">
                        <a @click="removeRow(index)" class="btn btn-block btn-danger rounded" href="javascript:void(0)"><i class="fa fa-trash-alt"></i></a>
                    </div>
                    <div class="col-md mg-t-10 mg-md-t-0">
                        <a @click="addRow" class="btn btn-block btn-success rounded" href="javascript:void(0)" type="button" v-if="index === did_template.properties.length-1"><i
                                class="fa fa-plus-square"></i></a>
                    </div>
                </div>
            </div>
            <div class="row row-sm mg-t-20">
                <div class="col-lg-2 mg-t-5 offset-lg-8">
                    <button @click="createDidTemplate" class="btn btn-primary w-100 rounded font-weight-bold" type="button"> Submit
                    </button>
                </div>
                <div class="col-lg-2 mg-t-5">
                    <button @click="reset" class="btn btn-secondary w-100 rounded font-weight-bold" type="button"> Reset
                    </button>
                </div>
            </div>
            <input @change="handleRateUpload" accept=".xlsx" class="d-none" id="template_upload"
                   ref="template_upload" type="file"/>
        </template>
    </standard-page>
</template>

<script>
    import {mapGetters} from "vuex";
    import XLSX from "xlsx";

    export default {
        name: "NewTemplate",
        props: ['catalogid', 'catalogNameProp'],
        computed: {
            ...mapGetters(["currentUser", "currentOrg"])
        },
        data() {
            return {
                submitted: false,
                data_types: ['Bool', 'Text', 'Integer', 'Float', 'Date', 'File', 'Location'],
                template_types: ['Did', 'Claim'],
                did_template: {
                    org: null,
                    name: null,
                    allow_custom: true,
                    template_type: null,
                    properties: [
                        {
                            name: null,
                            fact_type: "Text",
                            required: true,
                            private: false,
                            property_group: null,
                            repeatable: false,
                        }
                    ]
                },
            }
        },
        methods: {
            addRow() {
                this.did_template.properties.push({
                    name: null,
                    type: "Text",
                    required: true,
                    private: false,
                });
            },
            removeRow(index) {
                this.did_template.properties.splice(index, 1);
            },
            changeDataType(row) {
                if (row.fact_type == 'Location') {
                    row.required = false;
                }
            },
            async createDidTemplate() {
                this.did_template.org = this.currentUser.org_id;
                if (this.did_template.template_type === 'Claim') {
                    this.did_template.properties = this.did_template.properties.map(p => ({
                        ...p,
                        private: false
                    }))
                }
                try {
                    await this.$identity.post(`/identity/catalogs/${this.catalogid}/templates`, this.did_template);
                    this.reset();
                    await this.$router.push({name: 'templates'});
                    this.$toastr.s("DID template successfully created", "Success");
                } catch (e) {
                    this.$toastr.e("Error retrieving Process Definition", "Error");
                } finally {

                }
            },
            reset() {
                this.did_template = {
                    org: null,
                    name: null,
                    allow_custom: false,
                    template_type: 'Did',
                    properties: [
                        {
                            name: null,
                            fact_type: "Text",
                            required: true,
                            private: false,
                            property_group: null,
                            repeatable: false,
                        }
                    ]
                }
            },
            async handleRateUpload() {
                try {
                    let file = this.$refs.template_upload.files[0];
                    let reader = new FileReader();
                    reader.onload = async (e) => {
                        try {
                            let data = new Uint8Array(e.target.result);
                            let workbook = XLSX.read(data, {type: 'array'});
                            let arr = XLSX.utils.sheet_to_json(workbook.Sheets["Template"]);
                            this.did_template.properties = arr;
                            this.$toastr.s('Template uploaded', 'Success');
                        } catch (e) {
                            this.$toastr.e('Template upload failed', 'Failure');
                        }
                    };
                    reader.readAsArrayBuffer(file);
                } catch (e) {
                    console.error(e)
                }
            },
        }
    }
</script>

<style scoped>

</style>